define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-gamification", ["exports", "discourse/routes/discourse", "@ember/object", "bootbox", "discourse-common/utils/decorators", "@ember/object/computed", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _discourse, _object, _bootbox, _decorators, _computed, _I18n, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    loading: false,
    creatingNew: false,
    newLeaderboardName: "",
    nameValid: (0, _computed.and)("newLeaderboardName"),
    sortedLeaderboards(leaderboards) {
      return leaderboards?.sortBy("updated_at").reverse() || [];
    },
    selectedLeaderboard(id) {
      if (!id) {
        return;
      }
      id = parseInt(id, 10);
      return this.model.leaderboards.findBy("id", id);
    },
    saveEditDisabled(name) {
      return !name;
    },
    createNewLeaderboard() {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      const data = {
        name: this.newLeaderboardName,
        created_by_id: this.currentUser.id
      };
      return (0, _ajax.ajax)("/admin/plugins/gamification/leaderboard", {
        data,
        type: "POST"
      }).then(leaderboard => {
        const newLeaderboard = _object.default.create(leaderboard);
        this.set("model.leaderboards", [newLeaderboard].concat(this.model.leaderboards));
        this.resetNewLeaderboard();
        this.setProperties({
          loading: false,
          selectedLeaderboardId: leaderboard.id
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    resetNewLeaderboard() {
      this.setProperties({
        creatingNew: false,
        newLeaderboardName: "",
        newLeaderboardId: null
      });
    },
    destroyLeaderboard(leaderboard) {
      _bootbox.default.confirm(_I18n.default.t("gamification.leaderboard.confirm_destroy"), confirm => {
        if (!confirm) {
          return;
        }
        this.set("loading", true);
        return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
          type: "DELETE"
        }).then(() => {
          this.model.leaderboards.removeObject(leaderboard);
          this.set("loading", false);
        }).catch(_ajaxError.popupAjaxError);
      });
    },
    saveEdit() {
      this.set("loading", true);
      const data = {
        name: this.selectedLeaderboard.name,
        to_date: this.selectedWebhook?.to_date,
        from_date: this.selectedWebhook?.from_date
      };
      return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${this.selectedLeaderboard.id}`, {
        data,
        type: "PUT"
      }).then(() => {
        this.selectedLeaderboard.set("updated_at", new Date());
        this.setProperties({
          loading: false,
          selectedLeaderboardId: null
        });
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "sortedLeaderboards", [(0, _decorators.default)("model.leaderboards.@each.updated_at")]], ["method", "selectedLeaderboard", [(0, _decorators.default)("selectedLeaderboardId")]], ["method", "saveEditDisabled", [(0, _decorators.default)("selectedLeaderboard.name")]], ["method", "createNewLeaderboard", [_object.action]], ["method", "resetNewLeaderboard", [_object.action]], ["method", "destroyLeaderboard", [_object.action]], ["method", "saveEdit", [_object.action]]]));
});