define("discourse/plugins/discourse-gamification/discourse/templates/connectors/user-profile-secondary/gamification-score", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.gamification_score}}
    <dl>
      <dt>
        {{i18n "gamification.score"}}
      </dt>
      <dd>
        {{model.gamification_score}}
      </dd>
    </dl>
  {{/if}}
  
  */
  {
    "id": "ZiUTbntf",
    "block": "[[[41,[30,0,[\"model\",\"gamification_score\"]],[[[1,\"  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"gamification.score\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"gamification_score\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-gamification/discourse/templates/connectors/user-profile-secondary/gamification-score.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-gamification/discourse/templates/connectors/user-profile-secondary/gamification-score.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/connectors/user-profile-secondary/gamification-score.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});